<template>
  <div class="page-user-interact">
    <UserInteractChart />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import UserInteractChart from '@/views/stats/user-interact-chart'

export default defineComponent({
  name: 'UserInteract',
  components: { UserInteractChart },
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.page-user-interact {
  position: relative;
}
</style>
