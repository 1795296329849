<template>
  <div class="component-base-stats">
    <div class="businessline-wrap">
      <div class="legend-conf">
        <div class="conf-L">
          <el-date-picker
            format="YYYY-MM-DD HH:mm"
            v-model="refBetweenTime"
            type="datetimerange"
            :shortcuts="shortcuts"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <span>&nbsp;&nbsp;</span>
          <el-radio-group v-model="refTimeScale">
            <el-radio label="day">日</el-radio>
            <el-radio label="week">周</el-radio>
            <el-radio label="month">月</el-radio>
          </el-radio-group>
        </div>
        <div class="conf-R"></div>
      </div>
      <div class="charts-body" :id="chartId" style="width: 100%"></div>
      <div class="legend-line">
        <el-checkbox-group v-model="refCombineTypeList">
          <div>
            <h5>总数</h5>
            <el-checkbox label="total_act_like">点赞数</el-checkbox>
            <el-checkbox label="total_act_comment">评论数</el-checkbox>
            <el-checkbox label="total_act_transmit">转发数</el-checkbox>
            <el-checkbox label="total_act_collect">收藏数</el-checkbox>
            <el-checkbox label="total_act_share">分享数</el-checkbox>
          </div>
          <div>
            <h5>按发文类型</h5>
            <el-checkbox label="total_act_like_long">点赞数(文章)</el-checkbox>
            <el-checkbox label="total_act_comment_long">评论数(文章)</el-checkbox>
            <el-checkbox label="total_act_transmit_long">转发数(文章)</el-checkbox>
            <el-checkbox label="total_act_collect_long">收藏数(文章)</el-checkbox>
            <el-checkbox label="total_act_share_long">分享数(文章)</el-checkbox>
            <br />
            <el-checkbox label="total_act_like_short">点赞数(帖子)</el-checkbox>
            <el-checkbox label="total_act_comment_short">评论数(帖子)</el-checkbox>
            <el-checkbox label="total_act_transmit_short">转发数(帖子)</el-checkbox>
            <el-checkbox label="total_act_collect_short">收藏数(帖子)</el-checkbox>
            <el-checkbox label="total_act_share_short">分享数(帖子)</el-checkbox>
          </div>
          <div>
            <h5>按平台</h5>
            <el-checkbox label="total_act_like_pc">点赞数(PC)</el-checkbox>
            <el-checkbox label="total_act_comment_pc">评论数(PC)</el-checkbox>
            <el-checkbox label="total_act_transmit_pc">转发数(PC)</el-checkbox>
            <el-checkbox label="total_act_collect_pc">收藏数(PC)</el-checkbox>
            <el-checkbox label="total_act_share_pc">分享数(PC)</el-checkbox>
            <br />
            <el-checkbox label="total_act_like_app">点赞数(APP)</el-checkbox>
            <el-checkbox label="total_act_comment_app">评论数(APP)</el-checkbox>
            <el-checkbox label="total_act_transmit_app">转发数(APP)</el-checkbox>
            <el-checkbox label="total_act_collect_app">收藏数(APP)</el-checkbox>
            <el-checkbox label="total_act_share_app">分享数(APP)</el-checkbox>
            <br />
            <el-checkbox label="total_act_like_ios">点赞数(IOS)</el-checkbox>
            <el-checkbox label="total_act_comment_ios">评论数(IOS)</el-checkbox>
            <el-checkbox label="total_act_transmit_ios">转发数(IOS)</el-checkbox>
            <el-checkbox label="total_act_collect_ios">收藏数(IOS)</el-checkbox>
            <el-checkbox label="total_act_share_ios">分享数(IOS)</el-checkbox>
            <br />
            <el-checkbox label="total_act_like_android">点赞数(Android)</el-checkbox>
            <el-checkbox label="total_act_comment_android">评论数(Android)</el-checkbox>
            <el-checkbox label="total_act_transmit_android">转发数(Android)</el-checkbox>
            <el-checkbox label="total_act_collect_android">收藏数(Android)</el-checkbox>
            <el-checkbox label="total_act_share_android">分享数(Android)</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { format } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { fetchContentStats } from '@/domains/stats/api'
import type { StatContentData } from '@/domains/stats/models/stats'
import { shortcuts, TimeScale, defaultBetweenTime, useHandleChartsInit, calcxAxis } from '@/views/stats/constant'

type CombineType =
  | 'total_act_like'
  | 'total_act_comment'
  | 'total_act_transmit'
  | 'total_act_collect'
  | 'total_act_share'
  | 'total_act_like_long'
  | 'total_act_comment_long'
  | 'total_act_transmit_long'
  | 'total_act_collect_long'
  | 'total_act_share_long'
  | 'total_act_like_short'
  | 'total_act_comment_short'
  | 'total_act_transmit_short'
  | 'total_act_collect_short'
  | 'total_act_share_short'
  | 'total_act_like_pc'
  | 'total_act_comment_pc'
  | 'total_act_transmit_pc'
  | 'total_act_collect_pc'
  | 'total_act_share_pc'
  | 'total_act_like_app'
  | 'total_act_comment_app'
  | 'total_act_transmit_app'
  | 'total_act_collect_app'
  | 'total_act_share_app'
  | 'total_act_like_ios'
  | 'total_act_comment_ios'
  | 'total_act_transmit_ios'
  | 'total_act_collect_ios'
  | 'total_act_share_ios'
  | 'total_act_like_android'
  | 'total_act_comment_android'
  | 'total_act_transmit_android'
  | 'total_act_collect_android'
  | 'total_act_share_android'

const defaultChartData = {
  title: {
    text: ''
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  toolbox: {
    feature: {
      // saveAsImage: {},
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    type: 'value'
  },
  series: []
}

const combineTypeMap: { [key: string]: string } = {
  total_act_like: '点赞数',
  total_act_comment: '评论数',
  total_act_transmit: '转发数',
  total_act_collect: '收藏数',
  total_act_share: '分享数',
  total_act_like_long: '点赞数(文章)',
  total_act_comment_long: '评论数(文章)',
  total_act_transmit_long: '转发数(文章)',
  total_act_collect_long: '收藏数(文章)',
  total_act_share_long: '分享数(文章)',
  total_act_like_short: '点赞数(帖子)',
  total_act_comment_short: '评论数(帖子)',
  total_act_transmit_short: '转发数(帖子)',
  total_act_collect_short: '收藏数(帖子)',
  total_act_share_short: '分享数(帖子)',
  total_act_like_pc: '点赞数(PC)',
  total_act_comment_pc: '评论数(PC)',
  total_act_transmit_pc: '转发数(PC)',
  total_act_collect_pc: '收藏数(PC)',
  total_act_share_pc: '分享数(PC)',
  total_act_like_app: '点赞数(APP)',
  total_act_comment_app: '评论数(APP)',
  total_act_transmit_app: '转发数(APP)',
  total_act_collect_app: '收藏数(APP)',
  total_act_share_app: '分享数(APP)',
  total_act_like_ios: '点赞数(IOS)',
  total_act_comment_ios: '评论数(IOS)',
  total_act_transmit_ios: '转发数(IOS)',
  total_act_collect_ios: '收藏数(IOS)',
  total_act_share_ios: '分享数(IOS)',
  total_act_like_android: '点赞数(Android)',
  total_act_comment_android: '评论数(Android)',
  total_act_transmit_android: '转发数(Android)',
  total_act_collect_android: '收藏数(Android)',
  total_act_share_android: '分享数(Android)'
}

interface BodyParams {
  article_type: Array<'long' | 'short'>
  behavior_type: Array<string>
  platform: Array<'android' | 'mweb' | 'pc' | 'ios'>
  key: string
}
function calcParamsByCombineType(type: CombineType): BodyParams {
  const body: BodyParams = {
    article_type: ['long', 'short'],
    behavior_type: [],
    platform: ['android', 'mweb', 'pc', 'ios'],
    key: 'resource_v'
  }
  if (/_like/.test(type)) body.behavior_type = ['ArticleLiked']
  if (/_comment/.test(type)) body.behavior_type = ['ArticleComment']
  if (/_transmit/.test(type)) body.behavior_type = ['ArticleForward']
  if (/_collect/.test(type)) body.behavior_type = ['ArticleCollected']
  if (/_share/.test(type)) body.behavior_type = ['ArticleShare']
  if (/_short/.test(type)) {
    body.article_type = ['short']
  }
  if (/_long/.test(type)) {
    body.article_type = ['long']
  }
  if (/_pc/.test(type)) {
    body.platform = ['pc']
  }
  if (/_app/.test(type)) {
    body.platform = ['ios', 'android']
  }
  if (/_ios/.test(type)) {
    body.platform = ['ios']
  }
  if (/_android/.test(type)) {
    body.platform = ['android']
  }
  return body
}

export default defineComponent({
  name: 'BaseStats',
  props: {
    content_type: {
      type: String,
      default: 'generate'
    }
  },
  setup(props, ctx) {
    const fetchMethod = fetchContentStats
    const chartId = `XGB-${Math.random().toString(36).slice(6).toUpperCase()}`
    const chartIns = useHandleChartsInit(`#${chartId}`, 'dark')
    const refBetweenTime = ref<Date[]>(defaultBetweenTime)
    const refCombineTypeList = ref<CombineType[]>(['total_act_like'])
    const refTimeScale = ref<TimeScale>('day')
    const renderChartData = cloneDeep(defaultChartData)
    let refCacheLines: any[] = []

    const clearCacheLine = () => {
      refCacheLines = []
    }

    // 拉取单个组合类型combineType
    const updateStatsCacheByCombineType = async (combineType: CombineType = 'total_act_like') => {
      const [start, end] = refBetweenTime.value
      const params = {
        period: refTimeScale.value,
        start: Math.round(start.getTime() / 1000),
        end: Math.round(end.getTime() / 1000)
      }
      const paddingParams = calcParamsByCombineType(combineType)
      Object.assign(params, paddingParams)
      if (refCacheLines.find((line) => line.cacheid === `${combineType}`)) return
      const api = fetchMethod(params).then((res: StatContentData) => {
        if (res.items && res.items.length) {
          res.items.sort((L, R) => L.timestamp - R.timestamp)
          const lines = res.items.map((item) => {
            const axis_text = format(item.timestamp * 1000, 'MM-dd')
            // 互动类型的数据统一取resource_v字段
            const axis_val = item.resource_v
            return [axis_text, axis_val]
          })
          refCacheLines.push({
            name: `${combineTypeMap[combineType]}`,
            cacheid: `${combineType}`,
            expires: Date.now() + 600 * 1000,
            type: 'line',
            data: lines
          })
        }
      })
      return api
    }

    // 更新整个组合类型列表
    const updateStatsFromCache = (combineTypeList: CombineType[]) => {
      const cacheIdMap: { [key: string]: boolean } = {}
      combineTypeList.forEach((type) => {
        cacheIdMap[type] = true
      })
      const series = refCacheLines.filter((line) => {
        return cacheIdMap[line.cacheid] === true
      })
      const isAdd = series.length > renderChartData.series.length
      renderChartData.series = series as never[]
      renderChartData.xAxis.data = calcxAxis(series) as never[]
      chartIns.value.setOption(cloneDeep(renderChartData), !isAdd)
    }

    const updateAsyncByCombineTypeList = () => {
      const updateQueue: Promise<any>[] = []
      refCombineTypeList.value.forEach((type: any) => {
        updateQueue.push(updateStatsCacheByCombineType(type))
      })
      Promise.all(updateQueue)
        .then(() => {
          updateStatsFromCache(refCombineTypeList.value)
        })
        .catch((err) => {
          console.log('watch存在API拉取失败')
          updateStatsFromCache(refCombineTypeList.value)
        })
    }

    onMounted(() => {
      updateAsyncByCombineTypeList()
    })

    watch(refCombineTypeList, (newTypes, oldTypes) => {
      if (newTypes.length === 0) {
        console.log('refCombineTypeList-清空')
        renderChartData.series = []
        chartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      updateAsyncByCombineTypeList()
    })

    watch([refBetweenTime, refTimeScale], () => {
      if (!refBetweenTime.value) {
        // 清空了时间
        renderChartData.series = []
        chartIns.value.setOption(cloneDeep(renderChartData), true)
        return
      }
      clearCacheLine()
      updateAsyncByCombineTypeList()
    })
    return {
      chartId,
      shortcuts,
      combineTypeMap,
      // reactive vars
      refBetweenTime,
      refCombineTypeList,
      refTimeScale
    }
  }
})
</script>
<style lang="scss">
.component-base-stats {
  position: relative;
  .legend-conf {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    width: 100%;
    .conf-R {
      margin-left: 10px;
    }
  }
  .charts-body {
    min-height: 500px;
    width: 100%;
  }
  .legend-line {
    font-size: 16px;
    margin: 20px 0;
    padding: 0 20px;
    .el-checkbox {
      min-width: 160px;
    }
    h5 {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
</style>
